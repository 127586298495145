import { createSlice } from "@reduxjs/toolkit";
import { reducerUtils } from "@store/reducer.util";
import { createSagaReducer } from "@store/saga.util";

const name = "clayful";

const initialState = {
  response: reducerUtils.initial(),
  getClayfulItemId: reducerUtils.initial(),
  insertClayfulImage: reducerUtils.initial(),
  getClayfulUser: reducerUtils.initial(),
  getClayfulBrands: reducerUtils.initial(),
  getClayfulProducts: reducerUtils.initial(),
  getClayfulProductsCount: reducerUtils.initial(),
  getClayfulAllProducts: reducerUtils.initial(),
  putClayfulAllVariants: reducerUtils.initial(),
  putClayfulVariants: reducerUtils.initial(),
  getClayfulAllCatalogs: reducerUtils.initial(),
  variantTotalCount: 0,
  variantCurrentCount: 0,
  variantCurrentId: null,

  putClayfulAllProducts: reducerUtils.initial(),
  putClayfulProducts: reducerUtils.initial(),
  productTotalCount: 0,
  productCurrentCount: 0,
  productCurrentId: null,

  pushClayfulPoint: reducerUtils.initial(),
  pushClayfulCoupons: reducerUtils.initial(),
  deleteClayfulCoupons: reducerUtils.initial(),
  pushClayfulProcess: {
    total: 0,
    complete: 0,
  },
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    ...createSagaReducer("getClayfulItemId", "getClayfulItemId"),
    ...createSagaReducer("insertClayfulImage", "insertClayfulImage"),
    ...createSagaReducer("getClayfulUser", "getClayfulUser"),
    ...createSagaReducer("getClayfulBrands", "getClayfulBrands"),
    ...createSagaReducer("getClayfulProducts", "getClayfulProducts"),
    ...createSagaReducer("getClayfulProductsCount", "getClayfulProductsCount"),
    ...createSagaReducer("getClayfulAllProducts", "getClayfulAllProducts"),
    ...createSagaReducer("putClayfulVariants", "putClayfulVariants"),
    ...createSagaReducer("putClayfulAllVariants", "putClayfulAllVariants"),
    ...createSagaReducer("putClayfulProducts", "putClayfulProducts"),
    ...createSagaReducer("putClayfulAllProducts", "putClayfulAllProducts"),
    ...createSagaReducer("pushClayfulPoint", "pushClayfulPoint"),
    ...createSagaReducer("pushClayfulCoupons", "pushClayfulCoupons"),
    ...createSagaReducer("deleteClayfulCoupons", "deleteClayfulCoupons"),
    ...createSagaReducer("getClayfulAllCatalogs", "getClayfulAllCatalogs"),

    reset: (state, action) => {
      state.getClayfulItemId = reducerUtils.initial();
      state.insertClayfulImage = reducerUtils.initial();
      state.getClayfulUser = reducerUtils.initial();
      state.getClayfulBrands = reducerUtils.initial();
      state.getClayfulProducts = reducerUtils.initial();
      state.getClayfulProductsCount = reducerUtils.initial();
      state.getClayfulAllProducts = reducerUtils.initial();
      state.putClayfulVariants = reducerUtils.initial();
      state.putClayfulAllVariants = reducerUtils.initial();

      state.putClayfulProducts = reducerUtils.initial();
      state.putClayfulAllProducts = reducerUtils.initial();
    },
    resetGetClayfulAllProducts: (state, action) => {
      state.getClayfulAllProducts = reducerUtils.initial();
    },
    setVariantCurrentCount: (state, action) => {
      state.variantCurrentCount = action.payload.count;
    },
    setVariantTotalCount: (state, action) => {
      state.variantTotalCount = action.payload.count;
    },
    setVariantCurrentId: (state, action) => {
      state.variantCurrentId = action.payload.variantId;
    },

    setProductCurrentCount: (state, action) => {
      state.productCurrentCount = action.payload.count;
    },
    setProductTotalCount: (state, action) => {
      state.productTotalCount = action.payload.count;
    },
    setProductCurrentId: (state, action) => {
      state.productCurrentId = action.payload.productId;
    },
    setPushClayfulPointData: (state, action) => {
      state.pushClayfulPoint.data = action.payload;
    },
    setPushClayfulCouponsData: (state, action) => {
      state.pushClayfulCoupons.data = action.payload;
    },
    setPushClayfulProcess: (state, action) => {
      state.pushClayfulProcess = action.payload;
    },
  },
});

export const CLAYFUL = slice.name;
export const clayfulReducer = slice.reducer;
export const clayfulAction = slice.actions;
