import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Typography,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ChartBar as ChartBarIcon } from "@icons/chart-bar";
import { NavItem } from "@components/layout/nav-item";
import { signIn, signOut, useSession } from "next-auth/react";
import CategoryIcon from "@mui/icons-material/Category";
import KeyIcon from "@mui/icons-material/Key";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import PetsIcon from "@mui/icons-material/Pets";
import GroupIcon from "@mui/icons-material/Group";
import QuizIcon from "@mui/icons-material/Quiz";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import DescriptionIcon from "@mui/icons-material/Description";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import PanToolIcon from "@mui/icons-material/PanTool";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import GavelIcon from "@mui/icons-material/Gavel";
import CelebrationIcon from "@mui/icons-material/Celebration";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StrollerTwoToneIcon from "@mui/icons-material/StrollerTwoTone";
import SentimentSatisfiedAltTwoToneIcon from "@mui/icons-material/SentimentSatisfiedAltTwoTone";

const items = [
  {
    title: "대시보드",
    level: 2,
    key: "dashboard-main",
    icon: <ChartBarIcon fontSize="small" />,
    subItems: [
      {
        href: "/",
        icon: <ChartBarIcon fontSize="small" />,
        title: "메인",
      },
    ],
  },
  {
    title: "챌린지",
    level: 2,
    key: "challenge-main",
    icon: <DirectionsRunIcon fontSize="small" />,
    subItems: [
      {
        href: "/challenge",
        icon: <DirectionsRunIcon fontSize="small" />,
        title: "챌린지 관리",
      },
      {
        href: "/packageChallenge",
        icon: <RocketLaunchIcon fontSize="small" />,
        title: "챌린지시리즈 관리",
      },
      {
        href: "/answerV2",
        icon: <GavelIcon fontSize="small" />,
        title: "1분챌린지 심사",
      },
      {
        href: "/keyword",
        icon: <KeyIcon fontSize="small" />,
        title: "키워드 관리",
      },
      // {
      //   href: "/answer",
      //   icon: <QuizIcon fontSize="small" />,
      //   title: "참여",
      // },
    ],
  },
  {
    title: "설채현케어 기준정보",
    level: 2,
    key: "careservice-base-info",
    icon: <StrollerTwoToneIcon fontSize="small" />,
    subItems: [
      {
        href: "/csb/section",
        icon: <ImportContactsIcon fontSize="small" />,
        title: "섹션",
      },
      {
        href: "/csb/form",
        icon: <ImportContactsIcon fontSize="small" />,
        title: "설문",
      },
    ],
  },
  {
    title: "설채현케어 관리",
    level: 2,
    key: "careservice-base-mng",
    icon: <PetsIcon fontSize="small" />,
    subItems: [
      {
        href: "/csm/formResp",
        icon: <ImportContactsIcon fontSize="small" />,
        title: "설문 응답",
      },
      {
        href: "/csm/clinic",
        icon: <ImportContactsIcon fontSize="small" />,
        title: "클리닉 전체 현황",
      },
    ],
  },
  // knollo play
  {
    title: "놀로플레이",
    level: 2,
    key: "knolloplay",
    icon: <SentimentSatisfiedAltTwoToneIcon fontSize="small" />,
    subItems: [
      {
        href: "/userPet",
        icon: <PetsIcon fontSize="small" />,
        title: "반려동물",
      },
      {
        href: "/petKind",
        icon: <AddReactionIcon fontSize="small" />,
        title: "반려동물 품종",
      },
      {
        href: "/play",
        target: "_blank",
        icon: <TrendingUpIcon fontSize="small" />,
        title: "플레이 사용현황",
      },
      {
        href: "/unit",
        target: "_blank",
        icon: <PanToolIcon fontSize="small" />,
        title: "플레이 유닛",
      },
      {
        href: "/answerV2/event",
        target: "_blank",
        icon: <CelebrationIcon fontSize="small" />,
        title: "망한 반려동물 사진 자랑대회",
      },
      {
        href: "https://www.notion.so/sparkpetkorea/2b6a3c0478214073b48b670cb54665db?v=aba50fccd46b4049afc47f3f60b5610d&pvs=4",
        target: "_blank",
        icon: <ImportContactsIcon fontSize="small" />,
        title: "메뉴얼",
      },
    ],
  },
  // Knollo Store
  {
    title: "놀로스토어",
    level: 2,
    key: "knllostore",
    icon: <ShoppingCartIcon fontSize="small" />,
    subItems: [
      {
        href: "/knolloStore",
        target: "_blank",
        icon: <ShoppingCartIcon fontSize="small" />,
        title: "놀로스토어",
      },
      {
        href: "/event",
        icon: <CelebrationIcon fontSize="small" />,
        title: "이벤트",
      },
      {
        href: "/point/total",
        target: "_blank",
        icon: <AttachMoneyIcon fontSize="small" />,
        title: "놀로포인트 전체 현황",
      },
      {
        href: "/point/user",
        target: "_blank",
        icon: <EmojiPeopleIcon fontSize="small" />,
        title: "사용자 놀로포인트 현황",
      },
      {
        href: "/catalogs",
        target: "_blank",
        icon: <EmojiPeopleIcon fontSize="small" />,
        title: "카탈로그 관리",
      },
      {
        href: "/point/coupon",
        target: "_blank",
        icon: <EmojiPeopleIcon fontSize="small" />,
        title: "포인트 및 쿠폰 발급",
      },
      {
        href: "/order",
        target: "/order",
        icon: <EmojiPeopleIcon fontSize="small" />,
        title: "주문 관리",
      },
      {
        href: "/salescheck",
        target: "/salescheck",
        icon: <EmojiPeopleIcon fontSize="small" />,
        title: "매출 조회",
      },
    ],
  },
  // SCM
  {
    title: "SCM",
    level: 2,
    key: "scm",
    icon: <LocalShippingIcon fontSize="small" />,
    subItems: [
      {
        href: "/scm/inventory",
        target: "_blank",
        icon: <LocalShippingIcon fontSize="small" />,
        title: "재고",
      },
      {
        href: "/scm/expired",
        target: "_blank",
        icon: <QueryBuilderIcon fontSize="small" />,
        title: "유통기한",
      },
      {
        href: "/scm/prepareshipping",
        target: "/scm/prepareshipping",
        icon: <EmojiPeopleIcon fontSize="small" />,
        title: "배송 준비",
      },
    ],
  },
  // 시스템 설정
  {
    title: "관리자",
    level: 2,
    key: "knlloadmin",
    icon: <GroupIcon fontSize="small" />,
    subItems: [
      {
        href: "/admin",
        icon: <GroupIcon fontSize="small" />,
        title: "운영자",
      },
      {
        href: "/commonCode",
        target: "_blank",
        icon: <DescriptionIcon fontSize="small" />,
        title: "공통코드 관리",
      },
      {
        href: "/attachment",
        target: "_blank",
        icon: <DescriptionIcon fontSize="small" />,
        title: "스토리지 관리",
      },
    ],
  },
];

export const DashboardSidebar = (props) => {
  const router = useRouter();
  const { open, onClose } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"), {
    defaultMatches: true,
    noSsr: false,
  });
  const { data: useData } = useSession();
  const pathname = router.pathname;
  const secondDiverIndex = pathname.indexOf("/", 1);
  const dividerIndex = secondDiverIndex > -1 ? secondDiverIndex : 0;
  const comparePath = secondDiverIndex > -1 ? pathname.substring(0, dividerIndex) : pathname;
  //console.log(`pathname : ${pathname}, secondDiverIndex : ${secondDiverIndex}, dividerIndex :${dividerIndex}, comparePath : ${comparePath}`)

  const pathArr = pathname.split("/");
  comparePath = "";
  const keyProp = "";
  JSON.parse(JSON.stringify(items)).map((item) => {
    item.subItems.map((subItem) => {
      comparePath = "";
      for (var i = 0; i < pathArr.length; i += 1) {
        if (i > 0) {
          comparePath += "/";
        }

        comparePath += pathArr[i];
        if (subItem.href === comparePath) {
          keyProp = item.key;
          return subItem.href;
        }
      }
    });
  });

  const [expanded, setExpanded] = useState(keyProp);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    if (open) {
      onClose?.();
    }
  }, [router.asPath]);

  const handleLogout = async () => {
    await signOut({
      callbackUrl: `${process.env.NEXT_PUBLIC_ADMIN_API_HOST}/login`,
    });
  };

  const content = (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div align="center">
          <Box sx={{ px: 2, py: 2 }}>
            <Box
              sx={{
                alignItems: "center",
                backgroundColor: "rgba(255, 255, 255, 0.04)",
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
                px: 3,
                py: 1,
                borderRadius: 1,
                justifyContent: "center",
              }}
            >
              <div align="center">
                <Typography color="inherit" variant="subtitle1" align="center">
                  놀로 어드민
                </Typography>
              </div>
            </Box>
            <Box sx={{ m: 1 }}>
              <Button color="primary" variant="contained" fullWidth onClick={handleLogout}>
                Logout
              </Button>
            </Box>
          </Box>
        </div>
        <Divider
          sx={{
            borderColor: "#2D3748",
            mb: 1,
          }}
        />
        <Box sx={{ flexGrow: 1 }}>
          {items.map((item) => (
            <Accordion
              key={item.key}
              expanded={expanded === item.key}
              onChange={handleChange(item.key)}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0)",
                color: "neutral.300",
                border: `1px solid rgba(255, 255, 255, 0)`,
                "&:not(:last-child)": {
                  borderBottom: 0,
                },
                "&::before": {
                  display: "none",
                },
                pb: 0.5,
              }}
              disableGutters
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography
                  variant="h6"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  {item.icon}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    ml: 1,
                  }}
                >
                  {item.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {item.subItems.map((subItem) => {
                  return (
                    <NavItem
                      key={subItem.title}
                      icon={subItem.icon}
                      href={subItem.href}
                      title={subItem.title}
                    />
                  );
                })}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
        <Divider sx={{ borderColor: "#2D3748" }} />
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "neutral.900",
            color: "#FFFFFF",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "neutral.900",
          color: "#FFFFFF",
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
