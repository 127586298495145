import { takeLatest, put, all, fork, select, delay, call, take } from "redux-saga/effects";
import { clayfulAction } from "@store/clayful/clayfulSlice";
import * as clayfulApi from "@store/clayful/clayfulApi";
import createAsyncSaga from "@store/saga.util";
import { chunkRequest, chunkRequestPushCoupons, chunkRequestPushPoint } from "@utils/helper";
import { snackbarAction } from "@store/snackbar/snackbarSlice";
import { eventChannel, END } from "redux-saga";

const getClayfulItemIdSaga = createAsyncSaga(
  clayfulApi.getClayfulItemId,
  clayfulAction.getClayfulItemIdSuccess,
  clayfulAction.getClayfulItemIdError
);

const insertClayfulImageSaga = createAsyncSaga(
  clayfulApi.insertClayfulImage,
  clayfulAction.insertClayfulImageSuccess,
  clayfulAction.insertClayfulImageError
);

const getClayfulUserSaga = createAsyncSaga(
  clayfulApi.getClayfulUser,
  clayfulAction.getClayfulUserSuccess,
  clayfulAction.getClayfulUserError
);

const getClayfulBrandsSaga = createAsyncSaga(
  clayfulApi.getClayfulBrands,
  clayfulAction.getClayfulBrandsSuccess,
  clayfulAction.getClayfulBrandsError
);

const getClayfulProductsSaga = createAsyncSaga(
  clayfulApi.getClayfulProducts,
  clayfulAction.getClayfulProductsSuccess,
  clayfulAction.getClayfulProductsError
);

const getClayfulProductsCountSaga = createAsyncSaga(
  clayfulApi.getClayfulProductsCount,
  clayfulAction.getClayfulProductsCountSuccess,
  clayfulAction.getClayfulProductsCountError
);

const getClayfulAllCatalogsSaga = createAsyncSaga(
  clayfulApi.getClayfulAllCatalogs,
  clayfulAction.getClayfulAllCatalogsSuccess,
  clayfulAction.getClayfulAllCatalogsError
);

function* getClayfulAllProductsSaga() {
  try {
    const count = yield call(clayfulApi.getClayfulProductsCount);
    const limit = 120;
    const pageNumber = Math.ceil(count.count.raw / limit);

    let result = [];

    const productData = yield all(
      Array.from({ length: pageNumber }, (v, index) => index + 1).map((page) => {
        return call(clayfulApi.getClayfulProducts, {
          limit,
          page,
          sort: "-createdAt",
        });
      })
    );

    productData.forEach((v) => {
      result.push(...v);
    });

    yield put(clayfulAction.getClayfulAllProductsSuccess(result));
  } catch (error) {
    yield put(clayfulAction.getClayfulAllProductsError(error));
  }
}

function* putClayfulAllVariantsSaga(action) {
  try {
    const data = action.payload;

    // ['상품ID', '상품명', '배송방법', '옵션', 'SKU', '단품/세트', '상품가',
    // '판매가(할인가)', '할인형태(percentage/fixed)',
    // '할인율/할인가', '재고', '등록일', '구매가능여부', '키워드', '콜렉션']
    // 첫줄 제거 칼럼 필드명
    data.splice(0, 1);

    let count = 0;
    yield put(clayfulAction.setVariantCurrentCount({ count }));

    for (let row of data) {
      if (row[3] !== "undefined") {
        count++;
        yield put(clayfulAction.putClayfulVariants());
        const response = yield call(clayfulApi.putClayfulVariants, {
          // 상품ID
          productId: row[0],

          // 옵션ID
          variantId: row[3],

          // 재고수량
          payload: {
            quantity: row[10],
          },
        });

        yield put(clayfulAction.setVariantCurrentId({ variantId: row[3] }));
        yield put(clayfulAction.setVariantCurrentCount({ count }));
        yield put(clayfulAction.putClayfulVariantsSuccess(response));
      }
    }

    yield put(
      clayfulAction.putClayfulAllVariantsSuccess({
        msg: "success",
        count,
      })
    );

    // 모든 재고 완료 후 초기화
    yield put(clayfulAction.putClayfulVariants());
    // 전체 엑셀 row
    yield put(clayfulAction.setVariantCurrentCount({ count: 0 }));
  } catch (error) {
    yield put(clayfulAction.putClayfulAllVariantsError(error));
  }
}

function* putClayfulAllProductsSaga(action) {
  try {
    const data = action.payload;

    // 첫줄이 예전 재고양식때문에 mycsv - 2023-11-17T164244.103
    if (data.length && !data[0][1]) {
      data.splice(0, 1);
    }

    // ['상품ID', '상품명', '배송방법', '옵션', 'SKU', '단품/세트', '상품가',
    // '판매가(할인가)', '할인형태(percentage/fixed)',
    // '할인율/할인가', '재고', '등록일', '구매가능여부', '키워드', '콜렉션']
    // 첫줄 제거 칼럼 필드명
    data.splice(0, 1);

    let count = 0;
    yield put(clayfulAction.setProductCurrentCount({ count }));

    for (let row of data) {
      if (row[0]) {
        count++;
        yield put(clayfulAction.putClayfulProducts());
        const response = yield call(clayfulApi.putClayfulProducts, {
          // 상품ID
          productId: row[0],

          // 재고수량
          payload: {
            meta: {
              date_expired: row[12],
              date_option_expired: row[13],
            },
            keywords: {
              ko: row[10],
            },
          },
        });

        yield put(clayfulAction.setProductCurrentId({ productId: row[0] }));
        yield put(clayfulAction.setProductCurrentCount({ count }));
        yield put(clayfulAction.putClayfulProductsSuccess(response));
      }
    }

    yield put(
      clayfulAction.putClayfulAllProductsSuccess({
        msg: "success",
        count,
      })
    );

    // 모든 재고 완료 후 초기화
    yield put(clayfulAction.putClayfulProducts());
    // 전체 엑셀 row
    yield put(clayfulAction.setProductCurrentCount({ count: 0 }));
  } catch (error) {
    yield put(clayfulAction.putClayfulAllProductsError(error));
  }
}

function* pushClayfulPointSaga(action) {
  try {
    yield put(
      clayfulAction.setPushClayfulProcess({ total: action.payload.excelData.length, complete: 0 })
    );

    const progressChannel = eventChannel((emit) => {
      const onProgress = (completed) => {
        emit(completed);
      };

      // async IIFE를 사용해 chunkRequestPushPoint 호출 및 완료 처리
      (async () => {
        try {
          const res = await chunkRequestPushPoint(action.payload, onProgress);
          emit({ done: true, res }); // 작업 완료 신호
          emit(END); // 채널 종료
        } catch (error) {
          console.error(error);
          emit(END);
        }
      })();

      return () => {};
    });

    // 채널에서 데이터를 받아 상태를 업데이트
    while (true) {
      const completed = yield take(progressChannel);

      if (completed.done) {
        const { res } = completed;
        yield put(clayfulAction.pushClayfulPointSuccess(res));
        yield put(
          clayfulAction.setPushClayfulProcess({
            total: action.payload.excelData.length,
            complete: action.payload.excelData.length,
          })
        );
        break;
      }

      yield put(
        clayfulAction.setPushClayfulProcess({
          total: action.payload.excelData.length,
          complete: completed,
        })
      );
    }
  } catch (err) {
    console.log(err);
  }
}

function* pushClayfulCouponsSaga(action) {
  try {
    yield put(
      clayfulAction.setPushClayfulProcess({ total: action.payload.excelData.length, complete: 0 })
    );

    const progressChannel = eventChannel((emit) => {
      const onProgress = (completed) => {
        emit(completed);
      };

      // async IIFE를 사용해 chunkRequestPushPoint 호출 및 완료 처리
      (async () => {
        try {
          const res = await chunkRequestPushCoupons(action.payload, onProgress);
          emit({ done: true, res }); // 작업 완료 신호
          emit(END); // 채널 종료
        } catch (error) {
          console.error(error);
          emit(END);
        }
      })();

      return () => {};
    });

    // 채널에서 데이터를 받아 상태를 업데이트
    while (true) {
      const completed = yield take(progressChannel);

      if (completed.done) {
        const { res } = completed;
        yield put(clayfulAction.pushClayfulCouponsSuccess(res));
        yield put(
          clayfulAction.setPushClayfulProcess({
            total: action.payload.excelData.length,
            complete: action.payload.excelData.length,
          })
        );
        yield put(
          snackbarAction.setSnackbar({ message: "쿠폰 지급이 완료되었습니다.", type: "success" })
        );
        break;
      }

      yield put(
        clayfulAction.setPushClayfulProcess({
          total: action.payload.excelData.length,
          complete: completed,
        })
      );
    }
  } catch (err) {
    console.log(err);
  }
}

function* deleteClayfulCouponsSaga(action) {
  try {
    console.log(action);
  } catch (err) {
    console.log(err);
  }
}

function* watchPushClayfulPoint() {
  yield takeLatest(clayfulAction.pushClayfulPoint, pushClayfulPointSaga);
}

function* watchPushClayfulCoupons() {
  yield takeLatest(clayfulAction.pushClayfulCoupons, pushClayfulCouponsSaga);
}

function* watchDeleteClayfulCoupons() {
  yield takeLatest(clayfulAction.deleteClayfulCoupons, deleteClayfulCouponsSaga);
}

function* watchGetClayful() {
  yield takeLatest(clayfulAction.getClayfulItemId, getClayfulItemIdSaga);
}

function* watchPostClayful() {
  yield takeLatest(clayfulAction.insertClayfulImage, insertClayfulImageSaga);
}

function* watchGetClayfulUser() {
  yield takeLatest(clayfulAction.getClayfulUser, getClayfulUserSaga);
}

function* watchGetClayfulBrands() {
  yield takeLatest(clayfulAction.getClayfulBrands, getClayfulBrandsSaga);
}

function* watchGetClayfulProducts() {
  yield takeLatest(clayfulAction.getClayfulProducts, getClayfulProductsSaga);
}

function* watchGetClayfulProductsCount() {
  yield takeLatest(clayfulAction.getClayfulProductsCount, getClayfulProductsCountSaga);
}

function* watchGetClayfulAllProducts() {
  yield takeLatest(clayfulAction.getClayfulAllProducts, getClayfulAllProductsSaga);
}

function* watchPutClayfulAllVariants() {
  yield takeLatest(clayfulAction.putClayfulAllVariants, putClayfulAllVariantsSaga);
}

function* watchPutClayfulAllProducts() {
  yield takeLatest(clayfulAction.putClayfulAllProducts, putClayfulAllProductsSaga);
}

function* watchGetClayfulAllCatalogs() {
  yield takeLatest(clayfulAction.getClayfulAllCatalogs, getClayfulAllCatalogsSaga);
}

export function* clayfulSaga() {
  yield all([
    fork(watchGetClayful),
    fork(watchPostClayful),
    fork(watchGetClayfulUser),
    fork(watchGetClayfulBrands),
    fork(watchGetClayfulProducts),
    fork(watchGetClayfulProductsCount),
    fork(watchGetClayfulAllProducts),
    fork(watchPutClayfulAllVariants),
    fork(watchPutClayfulAllProducts),
    fork(watchPushClayfulPoint),
    fork(watchPushClayfulCoupons),
    fork(watchDeleteClayfulCoupons),
    fork(watchGetClayfulAllCatalogs),
  ]);
}
